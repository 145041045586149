// import images
import Logo from '../src/img/header/logo.png';
import GalleryImg1 from '../src/img/gallery/1.png';
import GalleryImg2 from '../src/img/gallery/2.png';
import GalleryImg3 from '../src/img/gallery/3.png';
import GalleryImg4 from '../src/img/gallery/4.png';
import GalleryImg5 from '../src/img/gallery/5.png';
import GalleryImg6 from '../src/img/gallery/6.png';
import GalleryImg7 from '../src/img/gallery/7.png';
import GalleryImg8 from '../src/img/gallery/8.png';
import QuoteImg from '../src/img/testimonial/quote.svg';
// import icons
import { GrFacebookOption } from 'react-icons/gr';
import { IoMdArrowForward } from 'react-icons/io';
import { FiSend } from 'react-icons/fi';
import { FaPlay, FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import {
  IoLogoInstagram,
  IoLogoPinterest,
  IoLogoTwitter,
  IoLogoYoutube,
} from 'react-icons/io';

export const headerData = {
  logo: Logo,
};

export const navData = {
  items: [
    { href: '/', name: 'Inicio' },
    { href: '/', name: 'Sobre' },
    { href: '/', name: 'Galeria' },
    { href: '/', name: 'Entrevista' },
    { href: '/', name: 'Artigos' },
    { href: '/', name: 'Contato' },
  ],
};

export const socialData = [
  { href: '/', icon: <GrFacebookOption /> },
  { href: '/', icon: <IoLogoInstagram /> },
  { href: '/', icon: <IoLogoPinterest /> },
  { href: '/', icon: <IoLogoTwitter /> },
  { href: '/', icon: <IoLogoYoutube /> },
];

export const heroData = {
  title: 'Luiz Tatoo',
  subtitle:
    'As tatuagens têm seu próprio poder e magia. Eles não apenas embelezam o corpo, mas também a psique.',
  btnText: 'saiba mais',
  btnIcon: <IoMdArrowForward />,
};

export const aboutData = {
  title: 'Minha história:',
  subtitle1:
    'In velit arcu posuere integer. Dolor sit amet, consectetur nun adipiscing elit. Duis porttitor massa tellus hac vel ante sit sed scelerisque praesent duis volutpat laoreet.',
  subtitle2:
    'Nisl, sit molestie commodo congue. Etiam lectus risus in amet. Commodo molestie fames etiam aenean sed. Pellentesque et venenatis amet, tellus hac vel adipiscing sit. Placerat vitae nisl viverra faucibus tincidunt habitasse amet. Nunc, velit nunc, scelerisque imperdiet nunc.',
  btnText: 'Saber mais',
  btnIcon: <IoMdArrowForward />,
};

export const galleryData = {
  title: 'Confira minha galeria:',
  btnText: 'Ver tudo',
  btnIcon: <IoMdArrowForward />,
  images: [
    {
      src: GalleryImg1,
      original: GalleryImg1,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg2,
      original: GalleryImg2,
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg3,
      original: GalleryImg3,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg4,
      original: GalleryImg4,
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg5,
      original: GalleryImg5,
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg6,
      original: GalleryImg6,
      width: 464,
      height: 412,
    },
    {
      src: GalleryImg7,
      original: GalleryImg7,
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg8,
      original: GalleryImg8,
      width: 465,
      height: 412,
    },
  ],
};

export const interviewData = {
  title:
    '“Considere o que você deseja. Seu tatuador nunca vai te dizer qual tatuagem fazer.”',
  btnText: 'Veja agora',
  btnIcon: <FaPlay />,
};

export const testimonialData = [
  {
    quoteImg: QuoteImg,
    message:
      "Toda a equipe é extremamente gentil e amigável. Eles são fantásticos. Eles são ótimos no que fazem! E é único. Eles irão consultá-lo adequadamente.",
    name: 'Jack Geoffrey',
    occupation: 'Tatuador',
  },
  {
    quoteImg: QuoteImg,
    message:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam culpa asperiores officia natus dignissimos autem possimus molestias aperiam?',
    name: 'Douglas Hane',
    occupation: 'Tatuador',
  },
];

export const contactData = {
  title: 'Entre em contato comigo:',
  info: [
    {
      title: 'Escritório',
      subtitle:
        'In velit arcu posuere integer sit amet, consectetur nun adipiscing elit. Duis porttitor massa tellus',
      address: {
        icon: <FaMapMarkerAlt />,
        name: 'Rua X, Vila Velha,  Boituva - SP',
      },
      phone: {
        icon: <FaPhoneAlt />,
        number: '+55 15 98989-9898',
      },
      email: {
        icon: <FaEnvelope />,
        address: 'contact@gmail.com',
      },
      link: 'Obter localização',
    },
    {
      title: 'Escritório',
      subtitle:
        'Sit amet, consectetur nun in velit arcu posuere integer. Adipiscing elit duis porttitor massa tellus',
      address: {
        icon: <FaMapMarkerAlt />,
        name: 'Rua Y, Jardim Bela, Boituva - SP',
      },
      phone: {
        icon: <FaPhoneAlt />,
        number: '+55 15 98989-4893',
      },
      email: {
        icon: <FaEnvelope />,
        address: 'contact@gmail.com',
      },
      link: 'Obter localização',
    },
  ],
  form: {
    name: 'Escreva seu nome aqui',
    email: 'Escreva seu endereço de e-mail',
    message: 'Escreva suas mensagens aqui',
    btnText: 'Enviar',
  },
};

export const footerData = {
  about: {
    title: 'Sobre Luiz Tattoo',
    subtitle:
      'Sit amet, consectetur nun in velit arcu posuere integer. Adipiscing elit duis porttitor massa tellus',
    address: {
      icon: <FaMapMarkerAlt />,
      name: 'Rua Y, Jardim Bela, Boituva - SP',
    },
    phone: {
      icon: <FaPhoneAlt />,
      number: '+55 15 98989-4893',
    },
    email: {
      icon: <FaEnvelope />,
      address: 'contact@gmail.com',
    },
  },
  links: {
    title: 'Links Úteis',
    items: [
      { href: '/', name: 'Sobre mim' },
      { href: '/', name: 'Minha galeria' },
      { href: '/', name: 'Meus serviços' },
      { href: '/', name: 'Contate-me' },
    ],
  },
  program: {
    title: 'Expediente',
    items: [
      { name: 'Segunda - Terça / Com agendamento' },
      { name: 'Quarta - Quinta / 10:00 - 9:00pm' },
      { name: 'Sábado / 10:00 - 6:00pm' },
      { name: 'Domingo / Com agendamento' },
    ],
  },
  newsletter: {
    title: 'Boletim de Notícias',
    subtitle:
      'Elit duis porttitor massa tellus nun in velit arcu posuere integer.',
    form: {
      placeholder: 'Seu endereço de email',
      icon: <FiSend />,
    },
  },
};
